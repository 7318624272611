@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';


.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  padding: size(16px) size(32px);
  width: 100%;

  color: $color-dodger-blue;
  background-color: $color-transparent;

  border: none;
  border-radius: size(10px);

  appearance: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  transition: color $trans-default, background-color $trans-default, opacity $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-alice-blue;

      .btn__angle {
        opacity: 1;
      }
    }

    &:active {
      background-color: $color-transparent;
    }
  }

  &--width-auto {
    width: auto;
  }

  &--dodger-blue {
    color: $color-default-white;
    background-color: $color-dodger-blue;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-dodger-blue;
      opacity: 0;
      border-radius: size(10px);
      transition: opacity $trans-default;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-dodger-blue;

        &::before {
          opacity: 1;
        }
      }

      &:active {
        background-color: $color-dodger-blue;

        &::before {
          opacity: 0;
        }
      }
    }
  }

  &--alice-blue {
    background-color: $color-alice-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-white;
        background-color: $color-dodger-blue;

        svg path {
          stroke: $color-default-white;
        }
      }

      &:active {
        background-color: $color-alice-blue;
      }
    }
  }

  &--border{
    &-blue {
      border: size(1px) solid $color-dodger-blue;
      color: $color-dodger-blue;
      background-color: $color-transparent;
      //border-radius: size(8px);
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: $color-dodger-blue;
          color: $color-default-white;

          &::before {
            opacity: 1;
          }
        }

        &:active {
          background-color: $color-dodger-blue;
          color: $color-default-white;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  &--dark {
    color: $color-chambray;
    background: transparent;
    border: 1px solid rgba(67, 88, 117, 0.08);
  }

  &--red {
    color: $color-deep-blush;

    background-color: rgba($color-deep-blush, 0.1);

    svg {
      fill: $color-deep-blush;

      path {
        fill: $color-deep-blush;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: rgba($color-deep-blush, 0.2);
      }

      &:active {
        background-color: $color-transparent;
      }
    }
  }

  &--info {
    justify-content: flex-start;
    padding: size(12px) size(42px) size(16px) size(22px);

    text-align: start;
  }

  &--full-width {
    width: 100%
  }

  &__icon {
    flex-shrink: 0;
    width: size(12px);
    height: size(12px);
  }

  &__text {
    font-weight: 500;
    font-size: size(16px);
    line-height: size(19px);
    z-index: 1;
    pointer-events: none;
  }

  &__dot {
    position: absolute;
    top: size(10px);
    right: size(10px);
    min-width: size(6px);
    min-height: size(6px);
    background-color: $color-dodger-blue;
    border-radius: 50%;
    opacity: 0;
  }

  &__notify {
    position: absolute;
    top: size(10px);
    right: size(10px);
    min-width: size(14px);
    min-height: size(14px);

    font-weight: 600;
    font-size: size(8px);
    line-height: size(10px);

    display: flex;
    justify-content: center;
    align-items: center;

    color: $color-default-white;
    background-color: $color-dodger-blue;
    border-radius: 50%;
    opacity: 0;
  }

  svg + &__text {
    margin-left: size(12px);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-active {
    .btn__dot {
      opacity: 1;
    }

    .btn__notify {
      opacity: 1;
    }
  }

  &__triangle.btn__triangle {
    display: flex;
    margin-left: size(10px);

    transform: rotate(180deg);

    transition: transform $trans-default;

    svg {
      width: size(8px);
      height: size(4px);
      overflow: hidden;
    }
  }

  &__text-wrapper {
    display: block;
  }

  &__info-title {
    display: block;

    font-size: size(14px);
    line-height: size(20px);
    color: $color-rock-blue;
  }

  &__info-value {
    display: block;

    font-family: $ff-averta;
    font-size: size(16px);
    line-height: size(19px);
    color: $color-chambray;

    &--password {
      letter-spacing: 0.32em;
    }
  }

  &__angle.btn__angle {
    position: absolute;
    top: 50%;
    right: size(22px);

    display: flex;
    margin-left: size(10px);

    opacity: 0;

    transition: opacity $trans-default;
    transform: translateY(-50%);

    svg {
      width: size(8px);
      height: size(14px);
      overflow: hidden;
    }
  }

  &__info-list {
    display: block;
    margin-top: size(8px);
  }

  &__info-item {
    display: block;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__info-item-title {
    display: block;

    font-family: $ff-averta;
    font-size: size(16px);
    line-height: size(19px);
    color: $color-chambray;
  }

  &__info-item-subtitle {
    display: block;

    font-family: $ff-averta;
    font-size: size(12px);
    line-height: size(15px);
    color: $color-rock-blue;
  }
  &--disabled {
    opacity: .6;
    pointer-events: none;
  }
}

.btn--icon-left {
  svg + .btn__text  {
    margin-left: 0;
    margin-right: size(12px);
    order: -1;
  }
}

.btn--lg {
  min-height: size(70px);

  svg {
    width: size(20px);
    height: size(20px);
  }
}

.btn--md {
  min-height: size(54px);

  svg {
    width: size(14px);
    height: size(14px);
  }
}

.btn--sm {
  min-height: size(44px);
  padding-top: size(12px);
  padding-bottom: size(12px);

  svg {
    width: size(12px);
    height: size(12px);
  }
}

.btn--icon {
  padding: size(16px);
  min-height: auto;
  background-color: $color-default-white;

  .btn__text {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  svg{
    margin: 0 !important;
  }

  &.btn--lg {

    svg {
      width: size(24px);
      height: size(24px);
    }
  }

  &.btn--md {
    padding: size(15px);

    svg {
      width: size(20px);
      height: size(20px);
    }
  }

  &.btn--sm {
    padding: size(10px);
    border-radius: size(10px);

    svg {
      width: size(16px);
      height: size(16px);
    }
  }

  &.btn--notify {
    svg {
      width: size(22px);
      height: size(24px);
    }
  }
}

.btn--not-padding {
  padding: size(5px);
}

.btn--transparent {
  background-color: $color-transparent;
  &-hover {
    &:hover {
      background-color: $color-transparent;
      svg path {
        fill: $color-nero;
      }
    }
  }

}

.btn--reset {
  min-height: auto;
  padding: size(10px);
  border-radius: 0 size(4px) size(4px) 0;
  background-color: $color-rock-blue;

  .btn__text {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  svg {
    margin: 0 !important;
    width: size(7px);
    height: size(7px);
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-ship-cove;
    }

    &:active {
      background-color: $color-rock-blue;
    }
  }
}

div.button {
  pointer-events: none;
}
